.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.Login-left{
	background:url('assets/images/login-left.png') no-repeat center center fixed; ;
	background-size: cover;
}
.Registration-left{
	background: url('assets/images/register-left.png') no-repeat center center fixed;
	background-size: cover;
}

.Login-right{
  box-sizing: border-box;

}

.form-input{
	background-color: rgb(216, 214, 214);
	min-height: 50px;
}

.arrow-png{
	background: url('../assets/images/arrow.png');
	width: 20px;
	height: 20px;
}